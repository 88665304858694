import React, { useEffect } from 'react';
import { Box, Container, Stack, VStack, SimpleGrid, GridItem, Flex, Image, Accordion, AccordionIcon, AccordionItem, AccordionPanel, AccordionButton, Link } from '@chakra-ui/react';
import Heading from '../component/Heading/Heading';
import Text from '../component/SubHeading/SubHeading';
import ClassCard from '../component/Classcard/ClassCard';
import ContactForm from '../component/Form/ContactForm';
import LinkButton from '../component/Button/LinkButton';
import MetaTitle from './MetaTitle';
import WOW from 'wowjs';
import PriceBox from '../component/Price Component/PriceBox';
import WhatsincludedCard from '../component/WhatsIncluded Card/WhatsincludedCard';
import VideoSection from '../component/VideoSection/VideoSection';
import VideoBanner from '../component/VideoBanner/VideoBanner';
import BlogSection from '../component/Blog/BlogSection';



const metaTitle = "HYROX Training Dublin | Perpetua Fitness"
const metaDesc = "The world series of fitness racing. HYROX training in Dublin with Perpetua Fitness. HYROX is a global fitness race for Every Body."



function Highrox(props) {

  useEffect(() => {
    new WOW.WOW({
      live: true,
      offset: 0,
      mobile: true,
    }).init();
  }, [])

  return (
    <>
      <MetaTitle title={metaTitle} content={metaDesc} />
  
      <VideoBanner
        videobanner='../video/Hyrox-banner.mp4'
        title="HYROX DUBLIN"
        subtitle="THE WORLD SERIES OF FITNESS RACING"
        isButton={true}
        issubtextpara={false}
        buttontitle="Book a class"
        margin="0px 0 0"
        Link="/timetable"
        videoModal='https://www.youtube.com/embed/IOu_ndmzDKE'
        targetBlank={false}
        modalButtontitle="View The Experience"
        subtitleRight={<Box display='inline-block'><Image src='../images/map-white.svg' display='inline-block' top='12px' position='relative'></Image> Available at our Windmill Lane Facility</Box>}
        issubtitleRight={true}
        buttontitle3='hyrox simulation'
        pageLink='/hyrox-simulation-dublin'
      />

      <Box backgroundColor='black' padding="80px 0 0" >
        <Container maxW='container.xl'>
          <Heading
            as="h3"
            title="HYROX is a global fitness race for Every Body. Participants from all around the world compete in the exact same format. The race starts with a 1 km run, followed by 1 functional movement,which repeats 8 times, taking place in large indoor venues, creating a unique stadium like atmosphere for all participants."
            variant='medium'
            textAlign="center"
            lineHeight="1"
            width={{ lg: '100%', xl: '100%' }}
            className='wow animate fadeInUp'
            color='#fff'
          />
        </Container>
      </Box>

      <Flex backgroundColor='black' padding="80px 0px">
        <Container maxW='container.xl'>
          <Stack direction={{ base: 'column', md: 'row' }} spacing='30px' textAlign={{ base: 'left', lg: 'left' }} alignItems='center'>
            <Box w={{ base: '100%', lg: '50%' }} padding={{ md: '0px 20px', lg: '0px 0px' }}>
              <PriceBox
                color='white'
                currency='€'
                priceValue='215'
                time='/per month'
                description='Arrange a call with our Head of HYROX to get our induction to our classes and facilities'
                buttonText='Book a class'
                link='/timetable'
                buttonPading='0px'
                issubHeading={false}
                width={{ base: '100%', sm: '70%' }}
              >
              </PriceBox>
            </Box>
            <Box w={{ base: '100%', lg: '50%' }} padding={{ md: '0px 20px', lg: '0px 0px' }}>
              <Heading title='WHATS INCLUDED ' variant='small' color='white' margin='0px 0px 30px' padding={{ sm: '0px 0px 0px 0px', md: '0px 0px 0px 31px' }} textAlign={{ sm: 'center', ms: 'left' }}></Heading>
              <SimpleGrid columns={{ base: '1', lg: '2' }} spacing='0px' className='_whatsinclude_box_body'>
                <Box className='_whatsinclude_box' padding={{ base: '15px 10px', xl: '20px' }}>
                  <WhatsincludedCard 
                    title='Unlimited HYROX Classes'
                    headingColor='white'
                    buttonColor='white'
                    ButtonMargin='0px 0px 0px auto'
                    padding='0px'
                    spacing={props.padding}
                    alt="HYROX Training dublin - arrow icon"
                  >
                  </WhatsincludedCard >
                </Box>
                <Box className='_whatsinclude_box' padding={{ base: '15px 10px', xl: '20px' }}>
                  <WhatsincludedCard
                    title='Access to JustGYM Area'
                    headingColor='white'
                    buttonColor='white'
                    ButtonMargin='0px 0px 0px auto'
                    padding='0px'
                    spacing={props.padding}
                    alt="HYROX Training dublin - arrow icon"
                  >
                  </WhatsincludedCard >
                </Box>
                <Box className='_whatsinclude_box' padding={{ base: '15px 10px', xl: '20px' }}>
                  <WhatsincludedCard
                    title='Online Program'
                    headingColor='white'
                    buttonColor='white'
                    ButtonMargin='0px 0px 0px auto'
                    padding='0px'
                    spacing={props.padding}
                    alt="HYROX Training dublin - arrow icon"
                  >
                  </WhatsincludedCard >
                </Box>
                <Box className='_whatsinclude_box' padding={{ base: '15px 10px', xl: '20px' }}>
                  <WhatsincludedCard 
                    title='HYROX Workshops'
                    headingColor='white'
                    buttonColor='white'
                    ButtonMargin='0px 0px 0px auto'
                    padding='0px'
                    spacing={props.padding}
                    alt="HYROX Training dublin - arrow icon"
                  >
                  </WhatsincludedCard >
                </Box>
              </SimpleGrid>
            </Box>
          </Stack>
        </Container>
      </Flex>

      <Flex backgroundColor='black' padding="80px 0px" borderTop='1px solid #232323' borderBottom='1px solid #232323'>
        <Container maxW='container.xl'>
          <Stack direction={{ base: 'column', md: 'row' }} spacing='30px' textAlign={{ base: 'left', lg: 'left' }} alignItems='center'>
            <Box w={{ base: '100%', lg: '50%' }} padding={{ md: '0px 20px', lg: '0px 0px' }}>
              <PriceBox
                color='white'
                currency={false}
                priceValue='PAY AS YOU GO'
                time={false}
                description='PURCHASE CLASS CREDITS AND USE THEM TO BOOK INTO HYROX SESSIONS'
                buttonText='Book a class'
                link='/timetable'
                buttonPading='0px'
                issubHeading={false}
                isButton={true}
                width={{ base: '100%', sm: '70%' }}
              >
              </PriceBox>              
            </Box>
            <Box w={{ base: '100%', lg: '50%' }} padding={{ md: '0px 20px', lg: '0px 0px' }}>
              <Heading title='Additonal Info ' variant='small' color='white' margin='0px 0px 30px' padding={{ sm: '0px 0px 0px 0px', md: '0px 0px 0px 31px' }} textAlign={{ sm: 'center', ms: 'left' }}></Heading>
              <Text padding={{ sm: '0px 0px 0px 0px', md: '0px 0px 0px 31px' }} color='#fff' title='ANY STUDIO CREDIT CAN BE USED FOR HYROX AT PERPETUA! ALL STUDIO CREDIT TERMS AND CONDITIONS APPLY INCLUDING OUR 12-HOUR CANCELLATION POLICY' width='100%' ></Text>
            </Box>
          </Stack>
        </Container>
      </Flex>

      <Flex flexDirection='column' padding={{ sm: '0', md: '50px 0px 60px', lg: '70px 20px 70px', xl: '80px 0px 80px' }} background='#000' borderBottom='1px solid #232323'>
        <Container maxW='container.xl'>
          <Heading
            as="h3"
            title="What happens in a HYROX class"
            variant='medium'
            textAlign="center"
            lineHeight="1"
            width={{ lg: '100%', xl: '100%' }}
            padding={{ sm: '40px 0px', md: '0px 0px 70px' }}
            className='wow animate fadeInUp'
            color='#fff'
          >
          </Heading>
        </Container>
        <Container maxW='container-fluid'>
          <SimpleGrid
            columns={{ sm: '1', md: '2', lg: '2', xl: '3', xxl: '4' }}
            columnGap={4}
            rowGap={2}
            width="full"
            marginTop='50px'
            padding={{ sm: '0px', md: '0px 30px' }}
          >
            <GridItem className="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.7s">
              <ClassCard
                title='WARM UP'
                subtitle='Please arrive 15 minutes early and check in with our front desk. Our staff will introduce
                  you to the class coach. They will provide an introduction to how the class works and show you how to use our treadmills. We have 6-10 minutes set aside to start your session off with a dynamic warm-
                  up to prep your body for the activity to come, we will alternate between the treadmills and floor until your heart rate is up and your body is ready to go!'
                bg="url('/images/Hyrox/WarmUp.jpg')"
                isButton={false}
                marginBottom='30px'
                transform="uppercase"
                minHeight={{ sm: '450px', md: '450px', lg: '450px', xl: '500px', xxl: '600px' }}
              />
            </GridItem>
            <GridItem mt={{ sm: '40px', xl: '80px' }} className="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.9s">
              <ClassCard
                title='Conditioning'
                subtitle='Each session we will focus on your conditioning, so you are prepared for a HYROX event.
                  We will focus on running, rowing and ski erg technique as well as additional movements which are
                  you are expected to complete. Get ready to work and enjoy getting prepared for an event.'
                bg="url('/images/Hyrox/Conditioning.jpg')"
                isButton={false}
                marginBottom='30px'
                transform="uppercase"
                minHeight={{ sm: '450px', md: '450px', lg: '450px', xl: '500px', xxl: '600px' }}
              />
            </GridItem>
            <GridItem mt={{ sm: '0', lg: '0', xl: '160px' }} className="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.9s">
              <ClassCard
                title='Strength WORK'
                subtitle='To finish each session, we will do additional strength work. This is a big part of improving
                  your event times so we will look to build on leg and pulling strength.'
                bg="url('/images/Hyrox/Strength.jpg')"
                isButton={false}
                marginBottom='30px'
                transform="uppercase"
                minHeight={{ sm: '450px', md: '450px', lg: '450px', xl: '500px', xxl: '600px' }}
              />
            </GridItem>
            <GridItem mt={{ sm: '0', md: '40px', lg: '40px', xl: '0', xxl: '240px' }} className="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.9s">
              <ClassCard
                title='COOL DOWN & HIGH FIVES'
                subtitle='We have 5 minutes set aside to finish class with a cool-down to bring your heart rate back
                  to normal and stretch out your muscles. Afterward, you can shower on-site & grab a shake at our
                  Mojo Health Bar'
                bg="url('/images/Hyrox/Cooldown.jpg')"
                isButton={false}
                marginBottom='30px'
                transform="uppercase"
                minHeight={{ sm: '450px', md: '450px', lg: '450px', xl: '500px', xxl: '600px' }}
              />
            </GridItem>
          </SimpleGrid>
        </Container>
      </Flex>


      <VideoSection heading='Get Started with' headingSub='Hyrox Classes' video1='https://youtube.com/embed/kjuQKgCC1ZE?si=HBeyrouzPC_7SSeJ' video2='https://youtube.com/embed/OsqLDOMAzAE?si=wDXy6MxEQyXg0YmI' smallHeading='Check out a class' smallHeading2='Whats Included' midHeading='' midHeading2='' />
      {/* Faq */}

      <BlogSection catSlug='hyrox' />
      <Flex p={{ sm: '0px 0px 50px', md: '20px 0px 90px' }} marginTop='50px'>
        <Container maxW={{ sm: 'Container.sm', md: 'container.lg', lg: 'container.lg', xl: 'container.xl' }}>
          <Stack direction={{ sm: 'column', md: 'row' }} spacing='24px'>
            <Box width={{ sm: '100%', md: '50%' }} /*position={{ base: 'relative', md: 'sticky' }} top={{ sm: '0', md: '100px' }} height={{sm:'auto',md:'150px' }} textAlign={{ sm: 'center', md: 'left' }} */>
              <Box paddingLeft='30px' position={'relative'} _after={{ position: 'absolute', content: '""', width: '3px', height: '100%', top: '0', left: '0', background: 'Red' }}>
                <Heading title='FREQUENTLY ASKED QUESTIONS' lineHeight='1' padding='0px 0px 30px' margin='0px auto' ></Heading>
                <Text title='Questions about the workout or our gyms? We got you. Check out the FAQ below or contact us.' width='80%' ></Text>
              </Box>
            </Box>
            <Box width={{ sm: '100%', md: '50%' }}>
              <Accordion defaultIndex={[0]} allowMultiple className='Accor_item _black_acording  white_acording'>
                <AccordionItem >
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title='What is hyrox?' variant='extrasmall'></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title=' HYROX is a global fitness race for Every Body. Participants from all around the world compete in the exact same format. The race starts with a 1 km run, followed by 1 functional movement, which repeats 8 times, taking place in large indoor venues, creating a unique stadium like atmosphere for all participants.' margin='0px 0px 20px'></Text>
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title=' How do I join a class?' variant='extrasmall'></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title="You can choose between buying a studio credit to join the class or you can start a HYROX membership which gives you access to all HYROX classes and online programming. You can book a class through the Perpetua Fitness app." margin='0px 0px 20px'></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title="How many HYROX classes are available?" variant='extrasmall'></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title='We have 9 HYROX classes you can choose from. Monday 8.15am, 6.45pm, Tuesday 8.15am, Wednesday 8.15am, 6.45pm, Thursday 8.15am, Friday 8.15am, Saturday 8am and Sunday 10am. Classes range from 45-60mins.' margin='0px 0px 20px'></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title="What are your hours of operation?" variant='extrasmall'></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title='Windmill Lane Studio: Monday-Thursday 6am-9pm | Friday 6am-8pm | Saturday-Sunday 8am-2pm.' margin='0px 0px 20px'></Text>
                    <Text title='Lennox Street Studio: Monday-Friday 6am-12pm, 4pm-8pm | Saturday-Sunday 9am-12pm .' margin='0px 0px 20px'></Text>
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title="Do you have a place I can securely leave my stuff while I work out?" variant='extrasmall'></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title='We have lockers in our men’s and women’s changing rooms where you can leave your belongings. Each locker allows you to create a single-use code while you’re in the facility.' margin='0px 0px 20px'></Text>
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title="Do you have showers?" variant='extrasmall'></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title=" Yes, we have showers in both men’s and women’s changing rooms. We also provide shampoo, conditioner, body wash, hair dryers & straighteners (plus a few extra goodies you might need to replenish after your sweaty session with us!)" margin='0px 0px 20px'></Text>
                  </AccordionPanel>
                </AccordionItem>

              </Accordion>
            </Box>
          </Stack>
        </Container>
      </Flex>

      <VStack backgroundColor='black' padding='96px 0px' borderBottom='1px solid #232323'>
        <Container
          display='flex'
          maxW='container.xl'
          flexDirection={{ base: 'column', lg: 'row' }}
          alignItems='top'
          alignContent='center'
          justifyContent='center'
          justifyItems='center'
        >
          <Box w={{ base: '100%', lg: '50%' }} textAlign={{ base: 'center', lg: 'left' }} marginBottom={{ base: '50px', lg: '0' }}>
            <Heading title='LOOKING FOR MORE INFORMATION' variant='extralarge' lineHeight='1' margin='0px 0px 40px' color='white'></Heading>
            <Heading title='Chat with a member of the team' variant='smallmid' lineHeight='1' margin='0px 0px 30px' color='white'></Heading>
            <Link href='/contact-us' textDecoration='none' display='inline-block' _hover={{ textDecoration: 'none' }}>
              <LinkButton
                title='BOOK A CALL'
                Imguri='/images/red_arrow.svg'
                border='2px solid' borderColor='Red'
                padding='20px 25px'
                color='White'
              ></LinkButton>
            </Link>
          </Box>
          <Box w={{ base: '100%', lg: '50%' }}>
            <ContactForm pageName="Hyrox"></ContactForm>
          </Box>
        </Container>
      </VStack>

    </>
  );
}

export default Highrox;