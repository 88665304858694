import React from 'react';
import { SimpleGrid, Box, Flex, Heading as Heading2, GridItem } from '@chakra-ui/react';
import Text from '../SubHeading/SubHeading';
// import PriceCard from './PricingCard';
// import PriceCard2 from './PricingCard2';
import Heading from '../Heading/Heading';
import PriceCard3 from './PriceCard3';

function StudioPricingWraper(){
    return(
        <>
            <Box  padding={{sm:"0 0 30px",md:'0 0 50px'}}>
                <Heading title='Flexible Training' variant='extrasmall' />
                <Heading2 paddingTop='7px' textTransform="uppercase" letterSpacing='0px' fontSize={{ sm: "30px", md: "60px", lg: "90px", }} lineHeight="76%" fontFamily='Poppins'>Studio Credit Packs</Heading2>
                <SimpleGrid columns={{ sm: "1", md: "2" }} spacing={10} margin='30px 0px'>
                
                    <GridItem>
                        <Heading title='Flexible' variant='smallmid' padding='10px 0' />
                        <Text title='Use the credits for any of our studio class within their expiry date' color='#000' />
                    </GridItem>
                    <GridItem> 
                        <Heading title='Access To ALL Studio Class' variant='smallmid' padding='10px 0' />
                        <Text title='Attend Tread & Shred, Rumble, Rythm Ride, Ride 45 & HYROX class' color='#000' />
                    </GridItem>
                </SimpleGrid>
            </Box>
            <SimpleGrid columns={{ sm: "2", md: "2", lg: "3" }} spacing={{ sm: '5', md: '10' }}>

                <PriceCard3 key="2" currency="€33" planname="3 FOR €33" offer="best intro offer" link="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=104433" targetBlank='true' description="10 Day expiry" buttonTitle='Buy Now' tagTitle='OPENING OFFER' />

                <PriceCard3 key="3" currency="€25" planname="1 STUDIO CREDIT" offer="€25 per Credit" link="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=104430" targetBlank='true' description="30 Day expiry" buttonTitle='BUY NOW' />
                
                <PriceCard3 key="4" currency="€70" planname="3 STUDIO CREDITS" offer="€23.33 per Credit" targetBlank='true' description="30 Day expiry" link="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=107732" buttonTitle='Buy Now' />

                <PriceCard3 key="5" offer="€22.40 per Credit" currency="€112" planname="5 STUDIO CREDITS" description="45 Day expiry" link="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=104431" targetBlank='true' buttonTitle='Buy Now' />

                <PriceCard3 key="6" offer="€21.50 per Credit" currency="€215" planname="10 STUDIO CREDITS" description="3 Month expiry"  link="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=104432" targetBlank='true' buttonTitle='Buy Now' tagTitle='MOST POPULAR' />
                
                <PriceCard3 key="7" offer="€20 per Credit" currency="€300" planname="15 STUDIO CREDITS" description="6 Month expiry" link="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=107733" targetBlank='true' buttonTitle='Buy Now' />

                <PriceCard3 key="8" offer="€18.20 per Credit" currency="€364" planname="20 STUDIO CREDITS" description="9 Month expiry" link="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=105433" targetBlank='true' buttonTitle='Buy Now' />

                <PriceCard3 key="9" offer="€17 per Credit" currency="€510" planname="30 STUDIO CREDITS" description="12 Month expiry" link="https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=107734" targetBlank='true' buttonTitle='Buy Now' />

                <PriceCard3 key="1" currency="€63" planname="STUDENT 3-PACK" offer="€21 per Credit" buttonTitle='Enquire NOW' link="mailto:hello@perpetua.ie" targetBlank='true' description={<Box>30 Day expiry<br/>Must hold valid Student ID</Box>} tagTitle='STUDENT ONLY' />
            </SimpleGrid>
                
            <Box  padding={{sm:"50px 0 10px",md:'70px 0 10px'}}>
                <Heading title='TRAIN FOR LESS' variant='extrasmall' />
                <Heading2 paddingTop='20px' textTransform="uppercase" letterSpacing='0px' fontSize={{sm:"30px", md:"60px", lg:"90px"}} lineHeight= "76%" fontFamily='Poppins'>STUDIO MEMBERSHIPS  </Heading2>
            </Box>

            <SimpleGrid columns={{ sm: "1", md: "2", xl: "4" }} spacing={10} margin='30px 0px 0'>
                
                <GridItem>
                    <Box color='#D12C41' className='_icon' fontSize={{ sm: "20px", md: "30px" }} ><i class="bi bi-tag-fill"></i></Box> 
                    <Heading title='Train For Less' variant='smallmid' padding='10px 0' />
                    <Text title='Our studio memberships work out much cheaper for our to attend our studio class.' color='#000' />
                </GridItem>
                <GridItem>
                    <Box color='#D12C41' className='_icon' fontSize={{ sm: "20px", md: "30px" }}><i class="bi bi-trophy"></i></Box>  
                    <Heading title='Access To ALL Studio Class' variant='smallmid' padding='10px 0' />
                    <Text title='Attend Tread & Shred, Rumble, Rythm Ride, Ride 45 & HYROX class.' color='#000' />
                </GridItem>
                <GridItem>
                    <Box color='#D12C41' className='_icon' fontSize={{ sm: "20px", md: "30px" }}><i class="bi bi-lightning-charge"></i></Box>  
                    <Heading title='Train With A Buddy' variant='smallmid' padding='10px 0' />
                    <Text title='Want to train with your friends? Invite them to join a class with us.' color='#000' />
                </GridItem>
                <GridItem>
                    <Box color='#D12C41' className='_icon' fontSize={{ sm: "20px", md: "30px" }}><i class="bi bi-snow2"></i></Box> 
                    <Heading title='Freeze Your Membership' variant='smallmid' padding='10px 0' />
                    <Text title='Commit to our 6-month membership and you can freeze your membership once every
                    6 months.' color='#000' />
                </GridItem>
            </SimpleGrid>

            <Box>
                <Heading title='3 Month Commitment ' textAlign='center' padding='35px 0 ' variant='medium' color='#D12C41' /> 
                <SimpleGrid columns={{ sm: "1", md: "2", xl: "2", xxl: "3" }} spacing={{ sm: '5', md: '10' }}>
                    <PriceCard3 key="10" listItem='true' description='' offer="€21 per Credit" currency="€168" planname="STUDIO 8" credit="€21" guest="1 Guest Pass" link=" https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=40&prodId=181" targetBlank='true' buttonTitle='Buy Now' />

                    <PriceCard3 key="11" listItem='true' description='' offer="€18 per Credit" cardTitle="" currency="€216" planname="STUDIO 12" credit="€21" guest="2 Guest Passes" link="https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=40&prodId=179" targetBlank='true' buttonTitle='Buy Now' />
                    
                    <PriceCard3 key="12" listItem='true' description='' offer="€16.87 per Credit" cardTitle="" currency="€270" planname="STUDIO 16" credit="€19" guest="2 Guest Passes" link="https:/clients.mindbodyonline.com/classic/ws?studioid=535079&stype=40&prodId=183" targetBlank='true' buttonTitle='Buy Now' />
                </SimpleGrid>
            </Box>
            <Box>
                <Heading title='6 Month Commitment ' textAlign='center' padding='35px 0 ' variant='medium' color='#D12C41' /> 
                <SimpleGrid columns={{ sm: "1", md: "2", xl: "2", xxl: "3" }} spacing={{ sm: '5', md: '10' }}>

                    <PriceCard3 key="13" listItem='true' description='' offer="€19 per Credit" cardTitle="" currency="€152" planname="STUDIO 8" credit="€20" guest="1 Guest Pass" link="https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=40&prodId=167" targetBlank='true' freeze='Freeze your membership'icon="bi bi-snow2" buttonTitle='Buy Now' inbodyScan='1 Complimentary Inbody Scan' />

                    <PriceCard3 key="14" listItem='true' description='' offer="€16.83 per Credit" cardTitle="" currency="€202" planname="STUDIO 12" credit="€18" guest="2 Guest Passes" link="https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=40&prodId=182" targetBlank='true' freeze='Freeze your membership' icon="bi bi-snow2" buttonTitle='Buy Now' inbodyScan='1 Complimentary Inbody Scan' />

                    <PriceCard3 key="15" listItem='true' description='' offer="€15 per Credit" cardTitle="" currency="€240" planname="STUDIO 16" credit="€17" guest="2 Guest Passes" link="https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=40&prodId=169" targetBlank='true' freeze='Freeze your membership' icon="bi bi-snow2"  buttonTitle='Buy Now' inbodyScan='1 Complimentary Inbody Scan' />
                </SimpleGrid>
            </Box>

            <Box>
                <Heading title='12 Month Commitment ' textAlign='center' padding='35px 0 ' variant='medium' color='#D12C41' /> 
                <SimpleGrid columns={{ sm: "1", md: "2", xl: "2", xxl: "3" }} spacing={{ sm: '5', md: '10' }}>

                    <PriceCard3 key="13" listItem='true' description='' offer="€17.50 per Credit" cardTitle="" currency="€140" planname="STUDIO 8" credit="€19" guest="1 Guest Pass" link="https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=40&prodId=175" targetBlank='true' freeze='Freeze your membership'icon="bi bi-snow2" buttonTitle='Buy Now' inbodyScan='2 Complimentary Inbody Scan' />

                    <PriceCard3 key="14" listItem='true' description='' offer="€16 per Credit" cardTitle="" currency="€192" planname="STUDIO 12" credit="€17" guest="2 Guest Passes" link="https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=40&prodId=176" targetBlank='true' freeze='Freeze your membership' icon="bi bi-snow2" buttonTitle='Buy Now' inbodyScan='2 Complimentary Inbody Scan' />

                    <PriceCard3 key="15" listItem='true' description='' offer="€14 per Credit" cardTitle="" currency="€224" planname="STUDIO 16" credit="€15" guest="2 Guest Passes" link="https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=40&prodId=177" targetBlank='true' freeze='Freeze your membership' icon="bi bi-snow2"  buttonTitle='Buy Now' inbodyScan='2 Complimentary Inbody Scan' />
                </SimpleGrid>
            </Box>
        </>
    )
}
export default StudioPricingWraper;